import '@babel/polyfill';
import React, {Component} from 'react';
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn'
import './App.css';
import router from './router'
import {buildRoute} from "./common/util/route";

import {HashRouter, Switch} from "react-router-dom";

import {createStore} from 'redux'
import rootReducer from './store/reducer'
import {Provider} from 'react-redux'

let store = createStore(rootReducer)

moment.locale('zh-cn');
// 打印初始状态
// console.log(store.getState())

class App extends Component {

    render() {
        let routerList = buildRoute(router)

        return (
            <ConfigProvider locale={zh_CN}>
                <Provider store={store}>
                    <HashRouter>
                        <Switch>
                            {routerList}
                        </Switch>
                    </HashRouter>
                </Provider>
            </ConfigProvider>
        );
    }
}

export default App;
