import React from 'react'
import './layouts.scss'
import {Layout} from 'antd';
import {connect} from 'react-redux'

const {Content} = Layout;

class DefaultLayout extends React.Component {
    state = {};

    render() {
        const {content} = this.props;
        return (
            <Layout className="layout-box">
                <Content children={content}/>
            </Layout>
        );
    }
}

export default connect()(DefaultLayout)
