import {GET_COOKIE} from "./actions";

const cookieList = (state = [], action) => {
    switch (action.type) {
        case GET_COOKIE:
            return action.data;
        default:
            return state
    }
};

export default cookieList
