/*
 * action 类型
 */

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_AUTH = 'GET_USER_AUTH';

/*
 * action 创建函数
 */
export const getUserInfo = (data) => ({
    type: GET_USER_INFO,
    data: data
});

export const getUserAuth = (data) => ({
    type: GET_USER_AUTH,
    data: data
});
