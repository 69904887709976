import {GET_APP_NAME, SET_APP_NAME} from "./actions";

const appNameList = (state = [], action) => {
    switch (action.type) {
        case GET_APP_NAME:
            return action.data;
        default:
            return state
    }
};

const app_name = (state = '', action) => {
    switch (action.type) {
        case SET_APP_NAME:
            return action.data;
        default:
            return state
    }
};

export default {
    appNameList,
    app_name
}
