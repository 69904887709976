import {combineReducers} from 'redux';

import loginStatus from './login/reducers';
import userInfo from './user/reducers';
import appName from './appName/reducers';
import cookieList from './cookie/reducers';

// 聚合各 reducer
const rootReducer = combineReducers({
    loginStatus,
    ...userInfo,
    cookieList,
    ...appName
});
export default rootReducer;
