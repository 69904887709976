import {bloomAdApi} from "./index";

// 获取用户权限数据
export function getUserAppList(query) {
    return bloomAdApi.get({
        path: '/user/app_list',
        query,
    })
}

// 获取收入数据
export function getIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 获取收入数据
export function getPddIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/pdd_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 获取收入数据
export function getTaoBaoIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/tb_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getSDWIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/sdw_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getWYWXIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/wy_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getYWXSIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/yw_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getDuoyouIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/dy_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getSSBBIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/ssb_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getPlayletIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/md_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getInteractiveIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/ia_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

export function getMTIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/mt_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}


// 获取收入日统计
export function getIncomeStatisticsList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/summary_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 获取收入统计
export function getIncomeMonthStatisticsList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/month_summary_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 获取cookie列表
export function getCookieList() {
    return bloomAdApi.get({
        path: '/cookie/cookie_list',
    })
}

// 更新cookie
export function updateCookie(body = {
    cookie_name: '',
    cookie_value: ''
}) {
    return bloomAdApi.post({
        path: '/cookie/cookie_set',
        body
    })
}

// 获取用户权限数据
export function getAdNameList(query) {
    return bloomAdApi.get({
        path: '/info/ad_name_list',
        query: {
            ...query,
        },
    })
}

// 获取收入汇总数据
export function getAdSummaryList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/ad_summary_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 获取H5收入汇总数据
export function getH5SummaryList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/h5_income_summary',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 获取H5广告汇总数据
export function getH5AdInfoList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/h5_ad_info_summary',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 获取在线结算列表
export function getStatementList(query, pagination) {
    return bloomAdApi.get({
        path: '/statement/list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// 预览生成PDF
export function generateStatement(query) {
    return bloomAdApi.get({
        path: '/statement/generate_statement',
        query: {
            ...query,
        },
    })
}

// 取消生成PDF
export function cancelStatement(query) {
    return bloomAdApi.get({
        path: '/statement/cancel_statement',
        query: {
            ...query,
        },
    })
}

// 确认生成PDF
export function confirmStatement(query) {
    return bloomAdApi.get({
        path: '/statement/confirm_statement',
        query: {
            ...query,
        },
    })
}

// 更新支付
export function setStatementPaid(query) {
    return bloomAdApi.get({
        path: '/statement/set_statement_paid',
        query: {
            ...query,
        },
    })
}

// 获取用户权限列表
export function getUserAuthList() {
    return bloomAdApi.get({
        path: '/user/auth_list',
    })
}

// 获取
export function getUserSearchProjectList({
                                             project_name
                                         }, {
                                             page,
                                             page_size
                                         }) {

    return bloomAdApi.get({
        path: '/project_manage/search_project_list',
        query: {
            project_name,
            page,
            page_size
        }
    })
}

// 获取
export function updateProject(body = {projectName: ''}) {
    return bloomAdApi.post({
        path: '/project_manage/post_create_project',
        body
    })
}

// 获取
export function getMainInfo() {
    return bloomAdApi.get({
        path: '/summary/main_info',
    })
}

// 获取
export function getIncomeTrend({
                                   start_date,
                                   end_date,
                               }) {
    return bloomAdApi.get({
        path: '/summary/income_trend',
        query: {
            start_date,
            end_date
        }
    })
}

// 获取
export function getProjectIncome({
                                     page_size = 10,
                                     page = 0,
                                     order = 'desc',
                                     order_by = 1,
                                     app_name = ''
                                 }) {
    return bloomAdApi.get({
        path: '/summary/project_income_list',
        query: {
            page,
            page_size,
            order,
            order_by,
            app_name
        }
    })
}

export function getPageMsgList({
                                   page_size = 10,
                                   page = 0,
                                   order = 'desc',
                                   order_by = 1,
                                   app_name = ''
                               }) {
    return bloomAdApi.get({
        path: '/summary/project_income_list',
        query: {
            page,
            page_size,
            order,
            order_by,
            app_name
        }
    })
}

export function getAnnList({
                               start_date = '',
                               end_date = '',
                               page_size = 10,
                               page = 0,
                           }) {
    return bloomAdApi.get({
        path: '/announcement/ann_list',
        query: {
            page,
            page_size,
            start_date,
            end_date,
        }
    })
}

export function getAnnOpList({
                                 start_date = '',
                                 end_date = '',
                                 page_size = 10,
                                 page = 0,
                                 title = '',
                                 status = ''
                             }) {
    return bloomAdApi.get({
        path: '/announcement/ann_op_list',
        query: {
            page,
            page_size,
            start_date,
            end_date,
            title,
            status
        }
    })
}

export function getAnn(id) {
    return bloomAdApi.get({
        path: '/announcement/ann_info',
        query: {
            id
        }
    })
}

// 获取
export function getAnnOp(id) {
    return bloomAdApi.get({
        path: '/announcement/ann_op_info',
        query: {
            id
        }
    })
}

// 创建
export function createAnnOp(
    data = {
        "title": "这是通过接口发布的第一篇文章",
        "content": "这是文章的内容通过接口发布的"
    }) {
    return bloomAdApi.post({
        path: '/announcement/ann_op_create',
        body: data
    })
}

// 修改
export function updateAnnOp(
    // 修改文章内容，设置状态只需要id和status
    data = {
        "title": "This is my first article",
        "content": "这是第一篇公告的内容========",
        "id": 1,
        "status": 2
    }) {
    return bloomAdApi.post({
        path: '/announcement/ann_op_update',
        body: data,
    })
}

//
export function getBillGroupList() {
    return bloomAdApi.get({
        path: '/bill/bill_group_list',
    })
}

//
export function getBillMonthList({
                                     bill_group
                                 }) {
    return bloomAdApi.get({
        path: '/bill/bill_month_list',
        query: {
            bill_group
        }
    })
}

//
export function getBillInfo({
                                bill_group,
                                month
                            }) {
    return bloomAdApi.get({
        path: '/bill/bill_info',
        query: {
            bill_group,
            month
        }
    })
}

export function createBill(data = {
    "bill_group": "HaoWanBa(好玩吧、GBEX)",
    "month": "2020-03",
    "share_ratio": 0.7,
    "vat_ratio": 0.01,
    "adjustment": -5
}) {
    return bloomAdApi.post({
        path: '/bill/bill_create',
        body: data
    })
}

export function getBillList({
                                bill_group,
                                page,
                                page_size,
                                role,
                                start_date,
                                end_date,
                            }) {
    return bloomAdApi.get({
        path: role === 'bill_manage' ? '/bill/bill_list' : '/bill/bill_check_list',
        query: {
            bill_group,
            page,
            page_size,
            start_date,
            end_date
        }
    })
}

export function actionBill({
                               action,
                               bill_group,
                               month,
                           }) {
    return bloomAdApi.get({
        path: '/bill/bill_op',
        query: {
            action,
            bill_group,
            month
        }
    })
}

export function getBillDataList({
                                    start_month,
                                    end_month,
                                }) {
    return bloomAdApi.get({
        path: '/bill/bill_data_list',
        query: {
            start_month,
            end_month,
        }
    })
}

// 获取
export function getMessageList({
                                   company,
                                   start_date,
                                   end_date,
                                   page,
                                   page_size
                               }) {

    return bloomAdApi.get({
        path: '/message/message_list',
        query: {
            company,
            start_date,
            end_date,
            page,
            page_size
        }
    })
}

// 获取
export function getMessage({
                               date,
                               platform,
                               company,
                               origin_id
                           }) {

    return bloomAdApi.get({
        path: '/message/message_info',
        query: {
            date,
            platform,
            company,
            origin_id
        }
    })
}

// （1）获取unionId列表
export function getUnionIdList(query) {

    return bloomAdApi.get({
        path: '/ssb_admin/union_id_list',
        query
    })
}

// （2）查询某个UnionId的详细信息
export function getUnionIdInfo(query) {

    return bloomAdApi.get({
        path: '/ssb_admin/union_id_info',
        query
    })
}

// （3）查询某个UnionId的详细信息
export function updateUnionIdInfo(body = {
    union_id: '',
    coin_name: '',
    coin_ratio: '',
    notify_url: ''
}) {

    return bloomAdApi.post({
        path: '/ssb_admin/update_union_id_info',
        body
    })
}

//
export function testNotifyUrl(body = {
    notify_test_url: '',
    union_id: ''
}) {

    return bloomAdApi.post({
        path: '/ssb_admin/test_notify_url',
        body
    })
}

export function getSSBBProjectIncome({
                                         page_size = 10,
                                         page = 0,
                                         order = 'desc',
                                         order_by = 1,
                                         app_name = ''
                                     }) {
    return bloomAdApi.get({
        path: '/summary/ssb_project_income_list',
        query: {
            page,
            page_size,
            order,
            order_by,
            app_name
        }
    })
}

//
export function getUpSettlementList(data, query) {
    return bloomAdApi.get({
        path: '/bill/up_settlement_list',
        query: {
            ...data,
            ...query
        }
    })
}

//
export function updateBillInvoiceInfo(body) {
    return bloomAdApi.post({
        path: '/bill/bill_invoice_info',
        body
    })
}

//
export function updateBillPaidInfo(body) {
    return bloomAdApi.post({
        path: '/bill/bill_paid_info',
        body
    })
}

//
export function getBillInvoiceInfo({
                                       date,
                                       bill_group
                                   }) {
    return bloomAdApi.get({
        path: '/bill/bill_list',
        query: {
            page: 0,
            page_size: 1,
            start_date: date,
            end_date: date,
            bill_group
        }
    })
}

//
export function getSSBAdminOrderList({
                                         unionId,
                                         startDate,
                                         endDate,
                                         projectName,
                                         uid,
                                         status
                                     }, query) {
    return bloomAdApi.get({
        path: '/ssb_admin/order_list',
        query: {
            unionId,
            startDate,
            endDate,
            projectName,
            uid,
            status,
            ...query
        }
    })
}

export function getTaskIncomeList(query, pagination) {
    return bloomAdApi.get({
        path: '/income/bt_list',
        query: {
            ...query,
            ...pagination,
        },
    })
}

// （1）获取unionId列表
export function getAppIdList(query) {

    return bloomAdApi.get({
        path: '/bt_admin/app_id_list',
        query
    })
}

// （2）查询某个UnionId的详细信息
export function getAppIdInfo(query) {

    return bloomAdApi.get({
        path: '/bt_admin/app_id_info',
        query
    })
}

// （3）查询某个UnionId的详细信息
export function updateAppIdInfo(body = {

}) {

    return bloomAdApi.post({
        path: '/bt_admin/update_app_id_info',
        body
    })
}

export function testBtNotifyUrl(body = {
    order_notify_test_url: '',
    app_id: ''
}) {

    return bloomAdApi.post({
        path: '/bt_admin/test_notify_url',
        body
    })
}