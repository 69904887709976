import UserLayout from '../layouts/UserLayout'
import BasiceLayout from '../layouts/BasiceLayout'
import DefaultLayout from '../layouts/DefaultLayout'
import MobileLayout from '../layouts/MobileLayout'
import NotFound from '../layouts/NotFound'

import asyncComponent from '../components/AsyncComponent'

import Login from '../pages/login/index'
import Welcome from '../pages/data/welcome/index'
//
const HomeIncomeStatistics = asyncComponent(() => import('../pages/data/home/summary'))
const HomeIncomeCPA = asyncComponent(() => import('../pages/data/home/cpa'))
//
const AdList = asyncComponent(() => import("../pages/data/ad/list/index"));
const AdStatistics = asyncComponent(() => import("../pages/data/ad/statistics/index"));
const DataPdd = asyncComponent(() => import("../pages/data/pdd/index"));
const DataTaobao = asyncComponent(() => import("../pages/data/taobao/index"));
const DataStatisticsDaily = asyncComponent(() => import("../pages/data/statistics/daily"));
const DataStatisticsMonthly = asyncComponent(() => import("../pages/data/statistics/monthly"));
const DataSDW = asyncComponent(() => import("../pages/data/sdw/index"));
const DataWYWX = asyncComponent(() => import("../pages/data/wywx/index"));
const DataDuoyou = asyncComponent(() => import("../pages/data/duoyou/index"));
const DataSSBB = asyncComponent(() => import("../pages/data/ssbb/index"));
const DataPlaylet = asyncComponent(() => import("../pages/data/playlet/index"));
const DataTask = asyncComponent(() => import("../pages/data/task/index/index"));
const DataTaskProject = asyncComponent(() => import("../pages/data/task/project"));
const DataInteractive = asyncComponent(() => import("../pages/data/interactive/index"));
const DataMT = asyncComponent(() => import("../pages/data/mt/index"));
const DataYWXS = asyncComponent(() => import("../pages/data/ywxs/index"));
// const DataSettlement = asyncComponent(() => import("../pages/data/settlement/old"));
const DataSettlementSystem = asyncComponent(() => import("../pages/data/settlement/system"));
const DataSettlementList = asyncComponent(() => import("../pages/data/settlement/list"));
const DataSettlementUpstream = asyncComponent(() => import("../pages/data/settlement/upstream"));
const DataSettlementSearch = asyncComponent(() => import("../pages/data/settlement/search"));
const DataOperationProject = asyncComponent(() => import("../pages/data/operation/project"));
const DataOperationSearch = asyncComponent(() => import("../pages/data/operation/search"));

const NoticeManage = asyncComponent(() => import("../pages/data/notice/manage"));
const NoticeAdd = asyncComponent(() => import("../pages/data/notice/manage/add"));
const NoticeEdit = asyncComponent(() => import("../pages/data/notice/manage/edit"));
const NoticeDetail = asyncComponent(() => import("../pages/data/notice/manage/detail"));

const NoticeUser = asyncComponent(() => import("../pages/data/notice/user"));
const NoticeUserDetail = asyncComponent(() => import("../pages/data/notice/user/detail"));

const NoticeTop = asyncComponent(() => import("../pages/data/notice/top"));
const NoticeTopDetail = asyncComponent(() => import("../pages/data/notice/top/detail"));

const SdkIndex = asyncComponent(() => import("../pages/sdk/index"));
const SdkAdAndroid = asyncComponent(() => import("../pages/sdk/android/ad"));
const SdkAdIOS = asyncComponent(() => import("../pages/sdk/ios/ad"));
const SdkNewsIOS = asyncComponent(() => import("../pages/sdk/ios/news"));
const SdkVideoIOS = asyncComponent(() => import("../pages/sdk/ios/video"));
const SdkNewsAndroid = asyncComponent(() => import("../pages/sdk/android/news"));
const SdkVideoAndroid = asyncComponent(() => import("../pages/sdk/android/video"));
const SdkAdUni = asyncComponent(() => import("../pages/sdk/uniapp/ad"));
const SdkNewsUni = asyncComponent(() => import("../pages/sdk/uniapp/news"));
const SdkVideoUni = asyncComponent(() => import("../pages/sdk/uniapp/video"));
const SdkAdApiCloud = asyncComponent(() => import("../pages/sdk/apicloud/ad"));
const SdkNewsApiCloud = asyncComponent(() => import("../pages/sdk/apicloud/news"));
const SdkVideoApiCloud = asyncComponent(() => import("../pages/sdk/apicloud/video"));

const SdkCdnVideo = asyncComponent(() => import("../pages/sdk/cdn/video"));

const Sdk5plusAd = asyncComponent(() => import("../pages/sdk/5plus/ad"));
const Sdk5plusNews = asyncComponent(() => import("../pages/sdk/5plus/news"));
const Sdk5plusVideo = asyncComponent(() => import("../pages/sdk/5plus/video"));
const Sdk5plusPackAndroid = asyncComponent(() => import("../pages/sdk/5plus/pack/android"));
const Sdk5plusPackIOS = asyncComponent(() => import("../pages/sdk/5plus/pack/ios"));

const SdkFlutterAd = asyncComponent(() => import("../pages/sdk/flutter/ad"));
const SdkFlutterNews = asyncComponent(() => import("../pages/sdk/flutter/news"));
const SdkFlutterVideo = asyncComponent(() => import("../pages/sdk/flutter/video"));

const SdkCordovaAd = asyncComponent(() => import("../pages/sdk/cordova/ad"));
const SdkCordovaNews = asyncComponent(() => import("../pages/sdk/cordova/news"));
const SdkCordovaVideo = asyncComponent(() => import("../pages/sdk/cordova/video"));

const MobileIndex = asyncComponent(() => import("../pages/mobile/index"));

export default [
    {
        path: '/',
        redirect: '/user/login'
    },
    // user
    {
        path: '/user',
        component: UserLayout,
        routes: [
            {path: '/login', name: '登录', component: Login},
        ],
    },
    /*{
        path: '/sdk',
        component: DefaultLayout,
        routes: [
            {
                path: '/',
                name: 'SDK',
                component: SdkIndex,
            },
        ]
    },
    {
        path: '/doc',
        component: DefaultLayout,
        routes: [
            {
                path: '/android/adsdk',
                name: '广告SDK',
                component: SdkAdAndroid,
            },
            {
                path: '/android/newssdk',
                name: '新闻SDK',
                component: SdkNewsAndroid,
            },
            {
                path: '/android/videosdk',
                name: '短视频SDK',
                component: SdkVideoAndroid,
            },
            {
                path: '/ios/adsdk',
                name: '广告SDK',
                component: SdkAdIOS,
            },
            {
                path: '/ios/newssdk',
                name: '新闻SDK',
                component: SdkNewsIOS,
            }, {
                path: '/ios/videosdk',
                name: '短视频SDK',
                component: SdkVideoIOS,
            }, {
                path: '/uniapp/adsdk',
                name: '广告SDK',
                component: SdkAdUni,
            }, {
                path: '/uniapp/newssdk',
                name: '资讯SDK',
                component: SdkNewsUni,
            }, {
                path: '/uniapp/videosdk',
                name: '短视频SDK',
                component: SdkVideoUni,
            }, {
                path: '/apicloud/adsdk',
                name: '广告SDK',
                component: SdkAdApiCloud,
            }, {
                path: '/apicloud/newssdk',
                name: '资讯SDK',
                component: SdkNewsApiCloud,
            }, {
                path: '/apicloud/videosdk',
                name: '短视频SDK',
                component: SdkVideoApiCloud,
            }, {
                path: '/videosdk/cdn',
                name: '短视频SDK部署CDN',
                component: SdkCdnVideo,
            }, {
                path: '/5plus/adsdk',
                name: '5plus广告SDK',
                component: Sdk5plusAd,
            }, {
                path: '/5plus/newssdk',
                name: '5plus资讯SDK',
                component: Sdk5plusNews,
            }, {
                path: '/5plus/videosdk',
                name: '5plus短视频SDK',
                component: Sdk5plusVideo,
            }, {
                path: '/5plus/android/pack',
                name: '5plus安卓包',
                component: Sdk5plusPackAndroid,
            }, {
                path: '/5plus/ios/pack',
                name: '5plus苹果包',
                component: Sdk5plusPackIOS,
            }, {
                path: '/flutter/adsdk',
                name: 'flutter广告SDK',
                component: SdkFlutterAd,
            }, {
                path: '/flutter/newssdk',
                name: 'flutter资讯SDK',
                component: SdkFlutterNews,
            }, {
                path: '/flutter/videosdk',
                name: 'flutter短视频SDK',
                component: SdkFlutterVideo,
            }, {
                path: '/cordova/adsdk',
                name: 'cordova广告SDK',
                component: SdkCordovaAd,
            }, {
                path: '/cordova/newssdk',
                name: 'cordova资讯SDK',
                component: SdkCordovaNews,
            }, {
                path: '/cordova/videosdk',
                name: 'cordova短视频SDK',
                component: SdkCordovaVideo,
            },

        ]
    },*/
    // app
    {
        path: '/page',
        component: BasiceLayout,
        routes: [
            {
                path: '/home',
                name: '首页',
                icon: 'home',
                routes: [
                    {
                        path: '/welcome',
                        name: '欢迎页',
                        component: Welcome,
                    },
                    {
                        // auth_name: ['summary_data'],
                        path: '/summary',
                        name: '收入概览',
                        component: HomeIncomeStatistics,
                    },
                    /*{
                        roles: ['administrator'],
                        path: '/cpa',
                        name: 'CPA概览',
                        component: HomeIncomeCPA,
                    },*/
                ]
            },
            {
                path: '/ad',
                name: '广告位',
                icon: 'desktop',
                routes: [
                    {
                        path: '/list',
                        name: '明细',
                        component: AdList,
                    },
                    {
                        // auth_name: ['summary_data'],
                        path: '/statistics',
                        name: '汇总',
                        component: AdStatistics,
                    },
                ]
            },
            {
                path: '/data',
                name: '数据',
                icon: 'database',
                routes: [
                    {
                        path: '/pdd',
                        name: '拼多多',
                        component: DataPdd,
                    },
                    {
                        path: '/taobao',
                        name: '淘宝',
                        component: DataTaobao,
                    },
                    {
                        path: '/sdw',
                        name: 'h5游戏',
                        component: DataSDW,
                    },
                    {
                        path: '/wywx',
                        name: '小说WYWX',
                        component: DataWYWX,
                    },
                    {
                        path: '/duoyou',
                        name: 'App游戏',
                        component: DataDuoyou,
                    },
                    /*{
                        path: '/ssbb',
                        name: 'CPA任务变现',
                        component: DataSSBB,
                    },*/
                    {
                        path: '/interactive',
                        name: '互动',
                        component: DataInteractive,
                    },
                    {
                        path: '/mt',
                        name: '推送',
                        component: DataMT,
                    },
                    {
                        path: '/playlet',
                        name: '小剧场',
                        component: DataPlaylet,
                    },
                    {
                        path: '/ywxs',
                        name: '阅文小说',
                        component: DataYWXS,
                    },
                ],
            },
            {
                path: '/statistics',
                name: '统计',
                icon: 'pay-circle',
                routes: [
                    {
                        path: '/daily',
                        name: '日收入',
                        component: DataStatisticsDaily,
                    }, {
                        path: '/monthly',
                        // auth_name: ['summary_data'],
                        name: '月收入',
                        component: DataStatisticsMonthly,
                    }
                ],
            }, {
                path: '/settlement',
                name: '结算',
                icon: 'account-book',
                routes: [
                    {
                        path: '/upstream',
                        name: '上游结算单',
                        auth_name: ['settlement_list'],
                        component: DataSettlementUpstream,
                    }, {
                        path: '/list',
                        name: '结算明细',
                        auth_name: ['bill_manage'],
                        component: DataSettlementList,
                    }, {
                        path: '/system',
                        name: '结算系统',
                        component: DataSettlementSystem,
                    }, {
                        path: '/search',
                        name: '结算查询',
                        auth_name: ['bill_manage'],
                        component: DataSettlementSearch,
                    }
                ],
            }, /*{
                path: '/setting',
                name: 'CPA任务变现',
                icon: 'project',
                routes: [
                    {
                        path: '/project',
                        name: '配置',
                        component: DataOperationProject,
                    },
                    {
                        path: '/data',
                        name: '数据',
                        component: DataSSBB,
                    },
                    {
                        path: '/search',
                        name: '查询',
                        component: DataOperationSearch,
                    },
                ],
            }, {
                path: '/task',
                name: 'Task变现',
                icon: 'project',
                routes: [
                    {
                        path: '/project',
                        name: '配置',
                        component: DataTaskProject,
                    },
                    {
                        path: '/data',
                        name: '数据',
                        component: DataTask,
                    },
                ],
            },*/ /*{
                path: '/operation',
                name: '查询项目',
                icon: 'team',
                auth_name: ['project_manage'],
                /!*routes: [
                    {
                        path: '/project',
                        name: '项目',
                        // component: DataOperationProject,
                    }
                ],*!/
            },*/ {
                path: '/notice',
                name: '通知',
                icon: 'audit',
                routes: [
                    {
                        path: '/top',
                        name: '上游通知',
                        component: NoticeTop,
                        auth_name: ['basic_op'],
                        routes: [
                            {
                                path: '/detail/:id',
                                name: '通知详情',
                                component: NoticeTopDetail,
                            },
                        ]
                    },
                    {
                        path: '/manage',
                        name: '通知管理（仅对OP开放）',
                        component: NoticeManage,
                        auth_name: ['basic_op'],
                        routes: [
                            {
                                path: '/detail/:id',
                                name: '通知详情',
                                component: NoticeDetail,
                            },
                            {
                                path: '/edit/:id',
                                name: '修改通知',
                                component: NoticeEdit,
                            },
                            {
                                path: '/add',
                                name: '发布通知',
                                component: NoticeAdd,
                            },
                        ]
                    }, {
                        path: '/user',
                        name: '通知列表',
                        component: NoticeUser,
                        routes: [
                            {
                                path: '/detail/:id',
                                name: '通知详情',
                                component: NoticeUserDetail,
                            },
                        ]
                    },
                ],
            },
        ],
    },
    // mobile
    {
        path: '/mobile',
        component: MobileLayout,
        routes: [
            {
                path: '/statistics',
                name: 'mobile',
                component: MobileIndex
            }
        ]
    },
    {
        path: '404',
        component: NotFound,
    },
];
