import React from 'react'
import './layouts.scss'
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { UserOutlined } from '@ant-design/icons';
import { Layout, Avatar, Menu, Dropdown, message, Select } from 'antd';
import SideMenu from './SideMenu'

import {connect} from 'react-redux'
import {Redirect} from "react-router-dom";

import {getAccountInfo, accountLogout} from "../common/api/account";
import {getCookieList, getUserAuthList, getUserAppList, updateCookie} from "../common/api/bloomAd";
import {loginOut} from "../store/login/actions";
import {getUserInfo, getUserAuth} from "../store/user/actions";
import {getAppName, setAppName} from "../store/appName/actions";
import {getCookie} from "../store/cookie/actions";
import queryString from "query-string";
import UpdateCookieForm from "../components/commonForm/updateCookie";
import domain from "../common/util/domain";

const mapStateToProps = state => {

    return {
        loginStatus: state.loginStatus,
        userInfo: state.userInfo,
        appNameList: state.appNameList,
        cookieList: state.cookieList,
        app_name: state.app_name,
        userAuth: state.userAuth
    }
};

const mapDispatchToProps = dispatch => ({
    quitLogin: () => dispatch(loginOut()),
    getUserInfo: (data) => dispatch(getUserInfo(data)),
    getAppName: (data) => dispatch(getAppName(data)),
    setAppName: (data) => dispatch(setAppName(data)),
    getCookie: (data) => dispatch(getCookie(data)),
    getUserAuth: (data) => dispatch(getUserAuth(data)),
});

const {Header, Sider, Content} = Layout;
const Option = Select.Option;

class BasiceLayout extends React.Component {
    state = {
        collapsed: window.screen.width < 992,
        visible: false
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    componentWillMount = () => {

        getAccountInfo().then(res => {
            //
            this.props.getUserInfo(res);
            //
            this.getUserAuthList()
            // 获取用户权限
            this.getUserAppList();
            // 获取cookie
            // this.getCookieList()

        }).catch(error => {
            console.dir(error)

            switch (error.code) {
                case 1007:
                    this.props.quitLogin();
                    break;
                default:
                    message.warning(error.errMsg);
                    break;
            }

        });
    };

    logoutAction = () => {
        accountLogout().then(res => {
            // 清空数据
            this.props.setAppName('')
            //
            this.props.getAppName([])
            //
            this.props.getUserAuth([])
            //
            this.props.getUserInfo({});
            //
            this.props.quitLogin()
        }).catch(error => {
            console.log(error)
        })
    };

    handleModal = (visible) => {
        this.setState({
            visible
        })
    };

    onChange = (e) => {
        //
        this.props.setAppName(e)
        //
        const {history, location} = this.props;
        let query = queryString.parse(location.search);
        history.replace({
            search: '?' + queryString.stringify({
                ...query,
                app_name: e
            })
        })

    };

    getUserAuthList = () => {
        getUserAuthList().then(res => {
            this.props.getUserAuth(res)
        })
    };

    getUserAppList = () => {

        getUserAppList().then(appNameList => {

            // debug
            /*appNameList = appNameList.map((item)=>{
                return {
                    app_name:item,
                    cn_name:item
                }
            })*/

            this.props.getAppName(appNameList)

            let query = queryString.parse(this.props.location.search);
            let app_name = query.app_name ?
                query.app_name : (appNameList.length ?
                    (typeof appNameList[0] === 'string' ?
                        appNameList[0] : appNameList[0].app_name) : '');
            //
            this.props.setAppName(app_name)

        }).catch(error => {
            console.dir(error);
            // message.warning(error.errMsg);
        });
    };

    getCookieList = () => {
        getCookieList().then((res) => {
            this.props.getCookie(res)
        }).catch(error => {
            console.dir(error);
            // message.warning(error.errMsg);
        })
    };

    updateCookie = (formData) => {
        updateCookie(formData).then(res => {
            message.success('更新成功');
            //
            this.handleModal(false);
            //
            this.getCookieList()

        }).catch(error => {
            console.log(error);
            message.error(error.message)
        })
    };

    renderList = (list) => {
        return list.map(item => {

            let result = '';

            switch (typeof item) {
                case "object":
                    result = {
                        label: item.app_name + '（' + item.cn_name + '）',
                        value: item.app_name
                    };
                    break;
                case 'string':
                default:
                    result = {
                        label: item,
                        value: item
                    };
                    break;

            }

            return result

        })
    }

    render() {
        const {content, loginStatus, userInfo, appNameList, cookieList, app_name, userAuth} = this.props;
        const {visible} = this.state;

        // console.dir(this.props)

        let showContent = false;

        if(app_name){
            showContent = true
        }else{
            if(userAuth.length){
                showContent = true
            }
        }

        // console.log(this.props.location.pathname);

        let hideTopSelectBar = [
            '/page/home/welcome',
            '/page/home/summary',
            '/page/settlement/newList'
        ].indexOf(this.props.location.pathname) !== -1

        let newAppNameList = this.renderList(appNameList)

        let logo = domain.setClassName('logo')

        const menu = <Menu>
            {/*{cookieList.length > 1 && <Menu.Item>
                <div onClick={() => this.handleModal(true)}>更新Cookie</div>
            </Menu.Item>}*/}
            <Menu.Item>
                <div onClick={this.logoutAction}>退出</div>
            </Menu.Item>
        </Menu>;

        return (
            <Layout className="basice-layout-box">
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    trigger={null}
                    collapsible
                    className="layout-sider"
                    collapsed={this.state.collapsed}
                >
                    <div className={logo}/>
                    <SideMenu {...this.props} {...this.state}/>
                </Sider>
                <Layout className="right-layout">
                    <Header className="layout-header">
                        <LegacyIcon
                            className="trigger"
                            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.toggle}
                        />
                        {newAppNameList.length > 1 && !hideTopSelectBar && <Select value={app_name}
                                                              showSearch
                                                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                              onChange={this.onChange}>
                            {newAppNameList.map((item, index) => (
                                <Option key={index} value={item.value}>{item.label}</Option>))}
                        </Select>}
                        {/*TODO:Cookie手动更新*/}
                        {/*<Select style={{width: 180}}
                                value={app_name}
                                onChange={this.onChange}>
                        </Select>*/}
                        <div className="user-info">
                            <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                                <div>
                                    <span
                                        className="nickname">{userInfo.nickName ? userInfo.nickName : userInfo.email}</span>
                                    <Avatar icon={<UserOutlined />} src={userInfo.avatar}/>
                                </div>

                            </Dropdown>
                        </div>
                    </Header>
                    {loginStatus
                        ? showContent && <Content className="layout-content" children={content}/>
                        : <Redirect to={{pathname: "/user/login"}}/>}
                </Layout>
                {/**/}
                {cookieList.length > 1 && visible && <UpdateCookieForm
                    cookieList={cookieList}
                    handleModal={this.handleModal}
                    handleSubmit={this.updateCookie}
                    visible={visible}/>}
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasiceLayout)
