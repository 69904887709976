import React from 'react'
import './index.scss'
import domain from "../../common/util/domain";

export default class Footer extends React.Component {

    state = {
        year: new Date().getFullYear(),

        list: [
            {
                title: {
                    text: '关于我们',
                },
                content: [
                    {text: `${domain.dataJSON.ft.text}，是隶属于${domain.dataJSON.ft.company}旗下的品牌，秉承着让移动广告更有价值的追求，专注于解决APP变现需求。自${domain.dataJSON.ft.year}年公司成立以来，公司通过传统广告打下坚实的服务基础，并成功转型到移动端APP流量广告变现领域，帮助上千家客户实现APP流量通过SDK聚合广告方式变现。`},
                ]
            },
            {
                title: {
                    text: '联系我们',
                },
                content: [
                    {text: `商务邮箱：${domain.dataJSON.ft.email}`},
                    {text: domain.dataJSON.ft.address ? `联系地址：${domain.dataJSON.ft.address}` : ''},
                ]
            },
        ],
    };

    componentWillMount() {

    }

    render() {

        // console.dir(dataJSON)

        let {list} = this.state
        let {isShow = false} = this.props;

        return (
            <div className={'footer'}>
                <div className={'footer-container'}>

                    {isShow && <div className="body">
                        {list.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="item-title">
                                    {item.title.url && <a href={item.title.url} target="_blank"
                                                          rel='noopener noreferrer'>{item.title.text}</a>}
                                    {!item.title.url && <div>{item.title.text}</div>}
                                </div>
                                {item.content.map((text, index2) => (
                                    <a href={text.url} target="_blank" key={index2} rel='noopener noreferrer'>
                                        <p>{text.text}</p>
                                    </a>
                                ))}
                            </div>
                        ))}
                    </div>}

                    <div className="extra">
                        <p>

                            {domain.dataJSON.ft.year && <span style={{marginRight:8}}>Copyright ©
                                    {domain.dataJSON.ft.year !== this.state.year ? domain.dataJSON.ft.year + '-' + this.state.year : this.state.year}
                                </span>}
                            <span>{domain.dataJSON.ft.company}</span>
                        </p>
                        <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
                            <p>
                                <span>{domain.dataJSON.ft.beian}</span>
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
