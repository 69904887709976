import React from 'react'
import './layouts.scss'
import { UserOutlined } from '@ant-design/icons';
import {Layout, Avatar, Menu, Dropdown, message, Select} from 'antd';


import {connect} from 'react-redux'
import {Redirect} from "react-router-dom";

import {getAccountInfo, accountLogout} from "../common/api/account";
import {getCookieList, getUserAppList} from "../common/api/bloomAd";
import {loginOut} from "../store/login/actions";
import {getAppName, setAppName} from "../store/appName/actions";
import queryString from "query-string";
import {getUserInfo} from "../store/user/actions";
import {getCookie} from "../store/cookie/actions";

const mapStateToProps = state => ({
    loginStatus: state.loginStatus,
    userInfo: state.userInfo,
    appNameList: state.appNameList,
    cookieList: state.cookieList,
    app_name: state.app_name
});

const mapDispatchToProps = dispatch => ({
    quitLogin: () => dispatch(loginOut()),
    getUserInfo: (data) => dispatch(getUserInfo(data)),
    getAppName: (data) => dispatch(getAppName(data)),
    setAppName: (data) => dispatch(setAppName(data)),
    getCookie: (data) => dispatch(getCookie(data)),
});

const {Header, Content} = Layout;
const Option = Select.Option;

class MobileLayout extends React.Component {
    state = {
        collapsed: window.screen.width < 992,
        visible: false
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    componentWillMount = () => {

        getAccountInfo().then(res => {
            this.setState({
                userInfo: res
            }, () => {
                //
                this.props.getUserInfo(res);
                // 获取用户权限
                this.getUserAppList();
                // 获取cookie
                this.getCookieList()

            })
        }).catch(error => {
            console.dir(error)

            switch (error.code) {
                case 1007:
                    this.props.quitLogin();
                    break;
                default:
                    message.warning(error.errMsg);
                    break;
            }

        });
    };

    logoutAction = () => {
        accountLogout().then(res => {
            this.props.quitLogin()
        }).catch(error => {
            console.log(error)
        })
    };

    handleModal = (visible) => {
        this.setState({
            visible
        })
    };

    onChange = (e) => {
        //
        this.props.setAppName(e)
        //
        const {history, location} = this.props;
        let query = queryString.parse(location.search);
        history.replace({
            search: '?' + queryString.stringify({
                ...query,
                app_name: e
            })
        })

    };

    getUserAppList = () => {
        getUserAppList().then(appNameList => {

            // debug
            /*appNameList = appNameList.map((item)=>{
                return {
                    app_name:item,
                    cn_name:item
                }
            })*/

            this.props.getAppName(appNameList)

            let query = queryString.parse(this.props.location.search);
            let app_name = query.app_name ?
                query.app_name : (appNameList.length ?
                    (typeof appNameList[0] === 'string' ?
                        appNameList[0] : appNameList[0].app_name) : '');
            //
            this.props.setAppName(app_name)

        }).catch(error => {
            console.dir(error);
            // message.warning(error.errMsg);
        });
    };

    getCookieList = () => {
        getCookieList().then((res) => {
            this.props.getCookie(res)
        }).catch(error => {
            console.dir(error);
            // message.warning(error.errMsg);
        })
    };

    renderList = (list) => {
        return list.map(item => {

            let result = '';

            switch (typeof item) {
                case "object":
                    result = {
                        label: item.app_name + '（' + item.cn_name + '）',
                        value: item.app_name
                    };
                    break;
                case 'string':
                default:
                    result = {
                        label: item,
                        value: item
                    };
                    break;

            }

            return result

        })
    };

    switchPC = ()=>{
        this.props.history.replace({
            pathname: `/page/home/welcome`,
        })
    }

    render() {
        const {content, loginStatus, userInfo, app_name, appNameList} = this.props;

        let newAppNameList = this.renderList(appNameList)

        const menu = <Menu>
            <Menu.Item>
                <div onClick={this.switchPC}>切换PC</div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={this.logoutAction}>退出</div>
            </Menu.Item>
        </Menu>;

        return (
            <Layout className="mobile-layout-box">
                <Layout className="right-layout">
                    <Header className="layout-header">
                        {newAppNameList.length > 1 ? <Select value={app_name}
                                                             showSearch
                                                             style={{marginLeft: 16, width: 120}}
                                                             filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                             onChange={this.onChange}>
                            {newAppNameList.map((item, index) => (
                                <Option key={index} value={item.value}>{item.label}</Option>))}
                        </Select> : <div style={{marginLeft:16,display:'inline-block'}}><strong>移动版</strong></div>}
                        <div className="user-info">
                            <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                                <div>
                                    <span
                                        className="nickname">{userInfo.nickName ? userInfo.nickName : userInfo.email}</span>
                                    <Avatar icon={<UserOutlined />} src={userInfo.avatar}/>
                                </div>
                            </Dropdown>
                        </div>
                    </Header>
                    {loginStatus
                        ? app_name && <Content className="layout-content" children={content}/>
                        : <Redirect to={{pathname: "/user/login"}}/>}
                </Layout>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileLayout)
