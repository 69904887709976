import React, {Component} from 'react';
import {NavLink, withRouter, Redirect} from 'react-router-dom';
import {Icon as LegacyIcon} from '@ant-design/compatible';
import {Menu} from 'antd';
import PropTypes from 'prop-types';
import {bdHost} from "../common/api";

const {SubMenu} = Menu;

class SideMenu extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    };

    getMenu = (path, name, icon) => {
        return <Menu.Item key={path}>
            <NavLink to={path}>
                {icon}
                <span>{name}</span>
            </NavLink>
        </Menu.Item>
    };

    getRoutes = () => {
        let {routes, userInfo, userAuth} = this.props;
        let {roles = []} = userInfo

        let newUserAuth = userAuth.map(item => {
            if (item.auth_value) {
                return item.auth_name
            }
            return ''
        })

        if (!userInfo.userId) {
            routes = routes.filter((item) => (item.path === '/home'))
        }

        // console.dir(userInfo)
        // console.dir(routes)

        if (userInfo.userId === "bloomad_data_446") {
            routes = routes.filter((item) => (item.path === '/settlement') || (item.path === '/home'))
        }
        // console.dir(routes)

        /*let newRoles = roles.map(item => {
            if (item.auth_value) {
                return item.auth_name
            }
            return ''
        })*/

        // console.dir(newUserAuth)

        // let auth_value = false;

        /*if (newUserAuth.length) {
            auth_value = !!(newUserAuth[0].auth_value)
        }*/

        // console.dir(this.props)
        // TODO：权限
        // console.dir(userInfo);
        // console.dir(routes);

        return routes.map((item) => {

            // 子路由
            routes = item.routes && item.routes.filter((subItem) => {

                // let value = true;

                // console.dir(subItem)

                /*if (subItem.show || subItem.hide) {
                    if (subItem.show) {
                        value = subItem.show.indexOf(userInfo.email) !== -1
                    }
                    if (subItem.hide) {
                        value = !(subItem.hide.indexOf(userInfo.email) !== -1)
                    }
                } else {
                    value = true
                }*/
                // console.dir(subItem)

                if (subItem.auth_name) {
                    return newUserAuth.filter(v => subItem.auth_name.includes(v)).length !== 0
                }
                if (subItem.roles) {
                    return roles.filter(v => subItem.roles.includes(v)).length !== 0
                }

                return true


            });

            let result = {
                ...item,
                // 子路由
                routes: routes && routes.map((item) => {

                    // console.dir(item)

                    return {
                        ...item,
                        hideInMenu: !userInfo,
                    }
                })
            };

            /*if (item.show) {
                result = {
                    ...result,
                    hideInMenu: !(item.show.indexOf(userInfo.email) !== -1)
                }
            }*/

            if (item.auth_name) {
                result = {
                    ...result,
                    hideInMenu: newUserAuth.filter(v => item.auth_name.includes(v)).length === 0
                }
            }
            if (item.roles) {
                result = {
                    ...result,
                    hideInMenu: roles.filter(v => item.roles.includes(v)).length === 0
                }
            }

            /*if (result.name === '数据' && bdHost.includes('regoad.com')) {
                result = {
                    ...result,
                    hideInMenu: true
                }
            }*/

            return result
        });
    };

    handleClick = (item) => {

        let {key} = item;

        if (key === '/page/operation') {
            window.open(window.location.protocol + '//' + bdHost)
        }

    }

    render() {
        const {location, match} = this.props;
        let rootPath = match.path;
        let defaultKey = [];
        let defaultPath = location.pathname;

        let firstPath = '';

        let menuList = [];

        let routes = this.getRoutes();

        // console.dir(routes)

        routes.forEach(route => {
            let routePath = rootPath + route.path;
            if (route.name) {
                let subList = [];
                if (route.routes) {
                    route.routes.forEach(sub => {
                        let path = routePath + sub.path;
                        if (defaultPath.indexOf(path) >= 0) {
                            defaultPath = path;
                        }
                        if (!firstPath) {
                            firstPath = path
                        }
                        if (sub.name && !sub.hideInMenu) {
                            subList.push(this.getMenu(path, sub.name))
                        }
                        //
                        if (defaultKey.indexOf(routePath) === -1) {
                            defaultKey.push(routePath)
                        }
                    });
                    //
                    if (!route.hideInMenu) {
                        menuList.push(
                            <SubMenu
                                key={routePath}
                                selectable={false}
                                title={
                                    <span>
                                    <LegacyIcon type={route.icon}/>
                                    <span>{route.name}</span>
                                  </span>
                                }>
                                {subList}
                            </SubMenu>
                        )
                    }

                } else {
                    if (route.name && !route.hideInMenu) {
                        menuList.push(this.getMenu(routePath, route.name, <LegacyIcon type={route.icon}/>))
                    }
                }
            }
        });

        // console.dir(defaultKey)
        // console.dir(defaultPath)

        return (
            !defaultKey ?
                <Redirect to={firstPath}/> :
                <Menu onClick={this.handleClick}
                      defaultOpenKeys={defaultKey}
                      selectedKeys={[defaultPath]}
                      theme="dark"
                      mode="inline">
                    {menuList}
                </Menu>
        )
    }
}

export default withRouter(SideMenu);
