import {accountApi} from "./index";

/**
 * POST /account/security_check
 * 用户登录
 *
 * @param body
 * @returns {*}
 */
export function accountLogin(body) {
    return accountApi.post({
        path: '/account/login',
        body
    })
}

/**
 * GET /account/logout
 * 退出登录
 *
 * @returns {*}
 */
export function accountLogout() {
    return accountApi.get({
        path: '/account/logout',
    })
}

/**
 * POST /account/info
 * 用户信息
 *
 * @returns {*}
 */
export function getAccountInfo() {
    return accountApi.get({
        path: '/account/info'
    })
}
