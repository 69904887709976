/*
 * action 类型
 */

export const GET_COOKIE = 'GET_COOKIE';

/*
 * action 创建函数
 */
export const getCookie = (data) => ({
    type: GET_COOKIE,
    data: data
});
