import React from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Modal } from 'antd';

const Option = Select.Option;
const TextArea = Input.TextArea;

class updateCookie extends React.Component {
    state = {};

    handleSearch = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            // console.log('Received values of form: ', values);

            if (!err) {

                let formData = {
                    'cookie_name': JSON.parse(values.cookie).cookieName,
                    'cookie_value': values.cookie_value,
                };

                this.props.handleSubmit(formData)
            }

        });
    };

    render() {
        const {form, visible, handleModal, cookieList} = this.props;
        const {getFieldDecorator, getFieldValue} = form;

        const formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18}
        };

        let cookie_old = getFieldValue('cookie') ? JSON.parse(getFieldValue('cookie')).cookieValue : '';

        return (
            <Modal visible={visible}
                   width={800}
                   title={'更新Cookie'}
                   onCancel={() => handleModal(false)}
                   onOk={this.handleSearch}>
                <Form layout={'horizontal'}>
                    <Form.Item label="Cookie名称" {...formItemLayout}>
                        {getFieldDecorator('cookie', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择要更新的Cookie'
                                }
                            ]
                        })(
                            <Select placeholder={'请选择要更新的Cookie'}
                                    showSearch
                            >
                                {cookieList.map((item, index) => <Option key={index}
                                                                         value={JSON.stringify(item)}>{item.cookieDesc+'-'+item.number}</Option>)}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={'Cookie旧值'} {...formItemLayout}>
                        <TextArea value={cookie_old}
                                  placeholder={'此处显示过期的Cookie'}
                                  autosize={{minRows: 10, maxRows: 10}}
                                  readOnly/>
                    </Form.Item>
                    <Form.Item label={'Cookie新值'} {...formItemLayout}>
                        {getFieldDecorator('cookie_value', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入更新的Cookie值'
                                }
                            ]
                        })(
                            <TextArea
                                placeholder={'此处输入更新的Cookie'}
                                autosize={{minRows: 10, maxRows: 10}}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>

        );
    }
}

export default Form.create({name: 'updateCookie_form'})(updateCookie);
