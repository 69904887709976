import React from 'react'
import './layouts.scss'
import {Layout} from 'antd';
import {connect} from 'react-redux'
import {Redirect} from "react-router-dom";

const {Content} = Layout;

const mapStateToProps = state => ({
    loginStatus: state.loginStatus
});

class UserLayout extends React.Component {
    state = {
        pathname: window.screen.width < 992 ? "/mobile/statistics" : "/page/home/welcome",
    };

    render() {
        const {content, loginStatus} = this.props;

        let {pathname} = this.state;

        // console.log(pathname)

        return (
            <Layout className="user-layout-box">
                {loginStatus ? <Redirect to={{pathname}}/> : <Content children={content}/>}
            </Layout>
        );
    }
}

export default connect(mapStateToProps)(UserLayout)
