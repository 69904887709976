/*
 * action 类型
 */

export const LOGIN_IN = 'LOGIN_IN';
export const LOGIN_OUT = 'LOGIN_OUT';

/*
 * action 创建函数
 */
export const loginIn = (data) => ({
    type: LOGIN_IN,
    data: data
});

export const loginOut = () => ({
    type: LOGIN_OUT,
});
