import opUtil from "../util/opUtil";
import {getAuth, getUserId, deleteUserId, deleteAuth} from "../util/auth";
import {message} from "antd";
import domain from "../util/domain";

let {dataJSON,platform} = domain;

const API_ENV = process.env.REACT_APP_API_ENV;
// console.log('API_ENV', API_ENV);
// console.log('USER_ID', getUserId());
// console.log(require("os").hostname())

const {Request, config: {COMMON_ENV}} = opUtil;

let tempQAEnv = API_ENV;
let host = dataJSON.extra.api ? dataJSON.extra.api : 'api.bloomad.cn';
let cdnHost = 'cdn.bloomad.cn';
let bdHost = dataJSON.extra.bd ? dataJSON.extra.bd :'bd.bloomad.cn';
if (API_ENV === COMMON_ENV.DEV || API_ENV === COMMON_ENV.QA) {
    tempQAEnv = COMMON_ENV.QA
    cdnHost = 'api.beekuaibao.com';
    bdHost = 'bd-qa.bloomad.cn';
}

const defaultParam = {
    timeout: 30000,
    domain: host,
    env: tempQAEnv,
    before: (config) => {
        config.headers = Object.assign({}, config.headers, {
            Authorization: getAuth(),
            UserId: getUserId(),
            Platform: platform
            // appId: 'bitwires',
            // osType: 'h5',
            // appVersion: '1.0.0(1)'
        });
        delete config.headers.beeOsType;
        return config
    }
};

const authParam = {
    after: (response) => {

        // console.dir(response)

        let {data} = response

        if (data.code === 1007) {
            message.destroy()
            message.error('出现异常，返回登录页', 3, () => {
                deleteUserId()
                deleteAuth()
                window.location.href = '/'
            })

        } else {

        }

        return response


    }
};

const accountApi = new Request({
    basePath: '/ad-account',
    ...defaultParam,
    ...authParam
});

const bloomAdApi = new Request({
    basePath: '/ad-data',
    ...defaultParam,
    ...authParam
});

const configApi = new Request({
    basePath: '/config',
    ...defaultParam,
    domain: cdnHost
})

const commonApi = new Request({
    basePath: '/common',
    ...defaultParam,
});

if (API_ENV === COMMON_ENV.DEV || API_ENV === COMMON_ENV.QA) {
    accountApi.domain = accountApi.domain.replace('-qa.', '-qa2.')
    bloomAdApi.domain = bloomAdApi.domain.replace('-qa.', '-qa2.')
    configApi.domain = configApi.domain.replace('-qa.', '-qa2.')
    commonApi.domain = commonApi.domain.replace('-qa.', '-qa2.')
}

export {
    accountApi,
    bloomAdApi,
    commonApi,
    configApi,

    bdHost,
}
