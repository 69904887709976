import React from 'react'
import {Result, Button} from 'antd';
import {Link} from "react-router-dom";

export default class NotFound extends React.Component {
    state = {
    };

    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle="对不起，当前页面不存在"
                extra={<Link to="/user/login"><Button type="primary">返回主页</Button></Link>}
            />
        );
    }
}
