import React from 'react'
import {Card,Calendar} from 'antd'

class Index extends React.Component {

    render() {
        return (
            <div className="welcome">
                <Card title={'日历'} extra={'欢迎登录！'}>
                    <Calendar/>
                </Card>
            </div>
        );
    }
}

export default (Index)
