import Cookie from 'js-cookie'
import domain from "./domain";

let {dataJSON} = domain;

let LOCAL_KEY = (dataJSON ? dataJSON.hd.text : 'ad') + '_op_h5_auth';
let LOCAL_KEY_2 = (dataJSON ? dataJSON.hd.text : 'ad') + '_op_h5_userId';

export function getAuth() {
    return getLocalValue(LOCAL_KEY)
}

export function setAuth(value = '', isRemember = true) {
    return setLocalValue({
        localKey: LOCAL_KEY,
        localValue: value,
        isRemember
    });
}

export function deleteAuth() {
    deleteLocalValue(LOCAL_KEY)
}

//
export function getUserId() {
    return getLocalValue(LOCAL_KEY_2)
}

export function setUserId(value = '', isRemember = true) {
    return setLocalValue({
        localKey: LOCAL_KEY_2,
        localValue: value,
        isRemember
    });
}

export function deleteUserId() {
    deleteLocalValue(LOCAL_KEY_2)
}

/* 工具库 */
//
function getLocalValue(localKey) {
    let localValue;
    if (typeof localValue === 'undefined') {
        localValue = Cookie.get(localKey) || localStorage.getItem(localKey) || ''
    }
    return localValue

}

function setLocalValue({localKey, localValue = '', isRemember = true}) {
    if (isRemember) {
        localStorage.setItem(localKey, localValue)
    } else {
        Cookie.set(localKey, localValue)
    }
    return localValue
}

function deleteLocalValue(localKey) {
    Cookie.remove(localKey);
    setLocalValue({
        localKey,
        localValue: '',
        isRemember: true
    })
}
