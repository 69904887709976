import {GET_USER_AUTH, GET_USER_INFO} from "./actions";

const userInfo = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_INFO:
            return action.data;
        default:
            return state
    }
};

const userAuth = (state = [], action) => {
    switch (action.type) {
        case GET_USER_AUTH:
            return action.data;
        default:
            return state
    }
};

export default {
    userInfo,
    userAuth
}
