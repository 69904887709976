/*
 * action 类型
 */

export const GET_APP_NAME = 'GET_APP_NAME';
export const SET_APP_NAME = 'SET_APP_NAME';

/*
 * action 创建函数
 */
export const getAppName = (data) => ({
    type: GET_APP_NAME,
    data: data
});

export const setAppName = (data) => ({
    type: SET_APP_NAME,
    data: data
});
