import classnames from 'classnames'
import os from 'os';

const hostname = os.hostname()
// console.log(hostname)
let result;

//
function setHTMLHeader(title) {
    // 修改title
    document.title = title
    // 修改favicon
    if (!document.querySelector("link[rel*='icon']")) {
        let link = document.createElement('link');
        link.type = 'image/png';
        link.rel = 'shortcut icon';
        link.href = require(`../assets/domain/${title}/image/Group.png`);
        document.getElementsByTagName('head')[0].appendChild(link)
    }
    //
    return {
        dataJSON: require(`../assets/domain/${title}/data.json`),
        logo: require(`../assets/domain/${title}/image/logo@2x.png`)
    }
}

//
function setClassName(className) {
    return classnames(className, {
        wokaiad: hostname.includes('wokaiad'),
        xiyuads: hostname.includes('xiyuads'),
        BloomAd: hostname.includes('bloomad'),
        AppPlusAd: hostname.includes('appplusad'),
        AdEngine: hostname.includes('adengine'),
        // 'D-AD': hostname.includes('d-ad') || hostname.includes('d-dou'),
        'CloudAd': hostname.includes('pluscloud'),
        'RegoAD': hostname.includes('regoad'),
        'WOSHI-AD': hostname.includes('woshiad'),
        'SSWW-AD': hostname.includes('sswwad'),
        'XY-AD': hostname.includes('xiyu-ad'),
        'XYAD': hostname.includes('xiyuad'),
        'YM-AD': hostname.includes('yimenapp'),
        'LINKIN-AD': hostname.includes('linkinad'),
    })
}

function getPlatform(result) {

    let platformData = {
        'wokaiad': 'data_wokaiad',
        'xiyuads': 'data_xiyuads',
        'BloomAd': 'data_bloomad',
        'AdEngine': 'data_adengine',
        'AppPlusAd': 'data_bloomad', // 无定义
        // 'D-AD': 'data_dad',
        'CloudAd': 'data_cloudad',
        'RegoAD': 'data_regoad',
        'SSWW-AD': 'data_dad',
        'WoshiAD': 'data_woshiad',
        'XY-AD': 'data_xyad',
        'XYAD': 'data_xyad',
        'YM-AD': 'data_yimenad',
        'LINKIN-AD':'data_linkinad',
    }

    return platformData[result]

}

let hostnameList = [{
    hostname: 'wokaiad',
    result: 'wokaiad'
},{
    hostname: 'xiyuads',
    result: 'xiyuads'
}, {
    hostname: 'bloomad',
    result: 'BloomAd'
}, {
    hostname: 'adengine',
    result: 'AdEngine'
}, {
    hostname: 'appplusad',
    result: 'AppPlusAd'
}, /*{
    hostname: 'd-ad',
    result: 'D-AD'
},*/{
    hostname: 'linkinad',
    result: 'LINKIN-AD'
}, {
    hostname: 'pluscloud',
    result: 'CloudAd'
}, {
    hostname: 'regoad',
    result: 'RegoAD'
}, /*{
    hostname: 'd-dou',
    result: 'D-AD'
},*/ {
    hostname: 'sswwad',
    result: 'SSWW-AD'
}, {
    hostname: 'woshiad',
    result: 'WoshiAD'
}, {
    hostname: 'xiyu-ad',
    result: 'XY-AD'
},{
    hostname: 'xiyuad',
    result: 'XYAD'
}, {
    hostname: 'yimenapp',
    result: 'YM-AD'
}]

let dataList = hostnameList.filter((item) => hostname.includes(item.hostname))

result = setHTMLHeader(dataList.length ? dataList[0].result : 'BloomAd')

let platform = getPlatform(dataList.length ? dataList[0].result : 'BloomAd')
// console.log(result)
// console.log(platform)
export default {
    ...result,
    hostname,
    platform,
    setClassName
}