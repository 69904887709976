import {
    getAuth, setAuth, deleteAuth,
    getUserId, setUserId, deleteUserId
} from "../../common/util/auth";
import {LOGIN_IN, LOGIN_OUT} from "./actions";

const initState = !!(getAuth() && getUserId());

const loginStatus = (state = initState, action) => {
    switch (action.type) {
        case LOGIN_IN:
            setAuth(action.data.authorization);
            setUserId(action.data.userId);
            return true;
        case LOGIN_OUT:
            deleteAuth();
            deleteUserId();
            return false;
        default:
            return state
    }
};

export default loginStatus
