import {Route, Redirect} from "react-router-dom";
import React from "react";

export function routeWithSubRoutes(router, root, list) {
    router.forEach(route => {
        let path = root + route.path;
        if (route.path && route.component) {
            list.push(
                <Route
                    exact
                    key={path}
                    path={path}
                    render={props => (
                        <route.component {...props} routes={route.routes} content={list}/>
                    )}
                />
            )
        }
        if (route.routes) {
            routeWithSubRoutes(route.routes, path, list)
        }
    })
}

export function buildRoute(router) {
    let routerList = [];
    router.forEach(route => {
        let list = [];
        let path = route.path;
        if (route.routes) {
            routeWithSubRoutes(route.routes, path, list);
            routerList.push(
                <Route
                    key={path}
                    path={path}
                    render={props => (
                        <route.component {...props} routes={route.routes} content={list}/>
                    )}
                />
            )
        } else if(route.redirect){
            routerList.push(<Redirect exact={true} key={route.path} from={route.path} to={route.redirect} />)
        } else if(route.component){
            routerList.push(<Route key={route.path} component={route.component} />)
        }
    });
    return routerList
}
