import React from 'react'
import './index.scss'
import './xiyu.scss'
import {connect} from 'react-redux'
import LoginForm from './LoginForm'
import Footer from '../../components/footer'
import {loginIn} from "../../store/login/actions";
import domain from "../../common/util/domain";

const mapDispatchToProps = dispatch => ({
    loginSuccess: (data) => dispatch(loginIn(data))
});

class Index extends React.Component {


    render() {

        // console.log(hostname)

        let loginFormBox = domain.setClassName('login-form-box')
        let loginLogo = domain.setClassName('login-logo')
        let loginTitle = domain.setClassName('login-title')
        let loginFormButton = domain.setClassName('login-form-button')
        //
        let zoom = (window.screen.width > 960 ? (window.screen.width / 1920) : 1)

        return (
            <div className={loginFormBox} style={{zoom: zoom}}>
                <div className="login-box">
                    <div className="login-content">
                        <div className={loginLogo}/>
                        <div className="login-form-container">
                            <LoginForm onSuccess={this.props.loginSuccess}
                                       loginTitle={loginTitle}
                                       loginFormButton={loginFormButton}/>
                        </div>
                    </div>
                </div>
                <div className={'login-footer'}>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    mapDispatchToProps
)(Index)
