import React from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, message, Modal } from 'antd';
import md5 from 'md5'
import {accountLogin} from "../../common/api/account";
import './LoginForm.scss';

class LoginForm extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                accountLogin({
                    "username": values.username,
                    "password": md5(values.password),
                }).then(res => {
                    this.props.onSuccess(res)
                }).catch(error => {
                    console.log(error);

                    switch (error.code) {
                        case 1011:
                            Modal.error({
                                title: '账号已注销',
                                content: <div>
                                    <p>账号：{error.data.email}</p>
                                    <p>名称：{error.data.nickname}</p>
                                </div>,
                            });
                            // Modal
                            break
                        default:
                            message.error(error.message || '未知异常')
                            break

                    }

                    /*{
                        "code": 1011,
                        "message": " account cancelled",
                        "data": {
                        "userId": "1",
                            "email": "weikinghe@163.com",
                            "nickname": "赫卫卿",
                            "authorization": "",
                            "platform": "",
                            "roles": null,
                            "adPlatforms": null
                    }
                    }*/
                })
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        let {loginFormButton,loginTitle} = this.props;

        return (
            <div>
                <div className={loginTitle}>
                    数据平台
                </div>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('username', {
                            rules: [{required: true, message: '请输入邮箱'}],
                        })(
                            <Input type="email" placeholder="请输入你的账号" size={'large'}/>,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{required: true, message: '请输入密码'}],
                        })(
                            <Input type="password" placeholder="请输入你的密码" size={'large'}/>,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {/*{getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(<Checkbox>记住密码</Checkbox>)}*/}
                        <Button type="primary" htmlType="submit" className={loginFormButton}>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default Form.create({name: 'normal_login'})(LoginForm);
